<style>
thead {
  background-color: #3d3d3d;
}

.v-data-table
  .v-table__wrapper
  > table
  > thead
  > tr
  > th.v-data-table__th--sortable:hover,
.v-data-table
  .v-table__wrapper
  > table
  tbody
  > tr
  > th.v-data-table__th--sortable:hover {
  cursor: unset;
}

.v-main {
  background-color: #dfdfdf;
}

.h2,
h2 {
  margin-bottom: 10px;
  font-weight: 600;
  border-bottom: 1px solid;
  font-size: 20px;
}
.v-table > .v-table__wrapper > table > thead > tr > th {
  padding: 0 7px !important;
}
</style>

<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2 style="margin-bottom: 15px; font-weight: 600; border-bottom: 1px solid">
      Filtros
    </h2>

    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label>N° Solicitud:</label>
        <v-text-field
          v-model="solId"
          type="number"
          pa-1
          hide-details
          ma-0
          variant="solo"
          clearable
          autocomplete="off"
          color="primary"
        />
      </div>

      <div class="w-in">
        <label>Ingresado Por:</label>

        <v-combobox
          v-model="solicitantePor"
          :items="lstSolicitantes"
          variant="solo"
          pa-1
          ma-0
          autocomplete="off"
          hide-details
          item-title="rutDisplayName"
          item-value="rut"
          clearable
          :disabled="
            !esAprobador &&
            !esAutorizador &&
            !esBodeguero &&
            !esContabilidad &&
            !esComprador &&
            !esVisualizador &&
            !esAdmin
          "
        >
        </v-combobox>
      </div>

      <!-- <div class="w-in">
        <label>Ingresado Por:</label>

        <v-combobox
          v-model="solicitantePor"
          :items="lstSolicitantes"
          variant="solo"
          pa-1
          ma-0
          hide-details
          item-title="rutDisplayName"
          item-value="rut"
          clearable
          :disabled="!esAdmin"
        >
        </v-combobox>
      </div> -->

      <div class="w-in">
        <label>Solicitado Por:</label>

        <v-combobox
          v-model="solicitante"
          :items="lstSolicitantes"
          variant="solo"
          pa-1
          hide-details
          autocomplete="off"
          ma-0
          item-title="rutDisplayName"
          item-value="rut"
          clearable
        >
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Tipo Solicitud:</label>

        <v-combobox
          pa-1
          hide-details
          ma-0
          autocomplete="off"
          v-model="tipoSol"
          :items="lstTipoSolicitud"
          variant="solo"
          item-title="id"
          item-value="nombre"
          @update:modelValue="seleccionarRetiroBodega"
        >
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Fecha Desde:</label>
        <v-text-field
          v-model="desde"
          type="date"
          hide-details
          autocomplete="off"
          pa-1
          ma-0
          clearable
          variant="solo"
          color="primary"
        />
      </div>

      <div class="w-in">
        <label>Fecha Hasta:</label>
        <v-text-field
          v-model="hasta"
          type="date"
          autocomplete="off"
          hide-details
          pa-1
          ma-0
          clearable
          variant="solo"
          color="primary"
        />
      </div>

      <div class="w-in">
        <label>Empresa:</label>

        <v-combobox
          v-model="empresaSel"
          :items="lstEmpresas"
          variant="solo"
          pa-1
          autocomplete="off"
          hide-details
          ma-0
          item-title="displayName"
          item-value="id"
          clearable
          @update:modelValue="selectCompany"
          @click:clear="selectClear"
        >
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Departamento:</label>

        <v-combobox
          v-model="departamentoSel"
          :items="lstDepartamento"
          variant="solo"
          pa-1
          autocomplete="off"
          hide-details
          ma-0
          item-title="codeDisplayName"
          item-value="id"
          clearable
        >
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Estado:</label>

        <v-combobox
          pa-1
          ma-0
          hide-details
          v-model="estadoSel"
          :items="lstEstados"
          autocomplete="off"
          variant="solo"
          item-title="id"
          item-value="nombre"
        >
        </v-combobox>
      </div>

      <div class="w-in">
        <div v-if="tipoSol?.id == 'Todos' || tipoSol?.id == 'Compra'">
          <label>Comprador:</label>

          <v-autocomplete
            v-model="compradorSel"
            :items="lstCompradores"
            variant="solo"
            item-title="rutDisplayName"
            autocomplete="off"
            item-value="rut"
            clearable
            hide-details
            return-object
          >
          </v-autocomplete>
        </div>

        <div v-if="tipoSol?.id == 'Retiro Bodega'">
          <label>Bodegas:</label>

          <v-combobox
            variant="solo"
            :items="lstBodegas"
            hide-details
            pa-1
            ma-0
            autocomplete="off"
            clearable
            item-title="codeDisplayName"
            item-value="code"
            return-object
            v-model="bodegaSel"
          >
          </v-combobox>
        </div>
      </div>

      <div class="w-in">
        <label>Num. BC365:</label>
        <v-text-field
          v-model="bc365"
          pa-1
          hide-details
          ma-0
          autocomplete="off"
          variant="solo"
          clearable
          color="primary"
        />
      </div>

      <div style="display: inline-flex" class="w-btn">
        <v-btn
          style="
            background-color: #a3a3a3;
            padding: 15px;
            border-radius: 15px;
            margin-top: 1em;
            color: white;
            margin-left: auto;
            display: flex;
          "
          density="default"
          @click="limpiarBusqueda()"
        >
          Limpiar
        </v-btn>
        <v-btn
          style="
            background-color: #595959;
            padding: 15px;
            border-radius: 15px;
            margin-top: 1em;
            color: white;
            margin-left: 10px;
            margin-right: auto;
            display: flex;
          "
          density="default"
          @click="buscarSolicitud()"
        >
          Buscar
        </v-btn>
      </div>
    </div>

    <div style="margin-top: 20px">
      <v-data-table :headers="headers" :items="lstDetalle">
        <template v-slot:item.code="{ item }">
          {{ item.code ? "SOC-" + item.code.toString().padStart(7, "0") : "" }}
        </template>

        <template v-slot:item.fechaSolicitud="{ item }">
          {{ formatDate(item.fechaSolicitud ?? "") }}
        </template>

        <!-- <template v-slot:item.retBodega="{ item }">
          <v-checkbox
            variant="outlined"
            density="compact"
            hide-details
            v-model="item.retBodega"
          >
          </v-checkbox>
        </template> -->

        <template v-slot:item.total="{ item }">
          {{ formatTotal(item.total ?? 0) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            icon="mdi-chevron-right"
            color="red"
            @click="navigate(item)"
          />
        </template>
      </v-data-table>
    </div>
  </div>

  <v-overlay
    :model-value="isLoading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular
      color="#a61a2f"
      size="50"
      :width="5"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script setup lang="ts">
import axios from "axios";
import type { Ref } from "vue";
import { reactive, ref } from "vue";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import { useCookies } from "vue3-cookies";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";
import type { Empleado } from "../types/empleado";
import type { Departamento } from "../types/departamento";
import type { TipoSolicitud } from "../types/tipoSolicitud";
import type { Company } from "../types/company";
import type { Estado } from "../types/estado";
import type { Location } from "../types/location";
import type { Rol } from "../types/rol";
import type { BuscarSolicitud } from "../types/buscarSolicitud";

import useEmpresas from "../composables/useEmpresa";
import useDepartamentos from "../composables/useDepartamento";
import useComprador from "../composables/useComprador";
import useSolicitante from "../composables/useSolicitante";
import { es } from "vuetify/locale";
// import useBodegas from "../composables/useBodega";

const store = useStore(key);
const { cookies } = useCookies();
const router = useRouter();
const route = useRoute();

const { getCompanies, lstEmpresas } = useEmpresas();
const { getDepartamentos, lstDepartamento } = useDepartamentos();
// const { getBodegas, lstBodegas } = useBodegas();
const { getEmpleadoComprador, lstCompradores } = useComprador();
const { getSolicitantes, lstSolicitantes } = useSolicitante();

let lstTipoSolicitud: TipoSolicitud[] = [
  { id: "Todos", nombre: "Todos" },
  { id: "Compra", nombre: "Compra" },
  { id: "Retiro Bodega", nombre: "Retiro Bodega" },
  { id: "Rendicion", nombre: "Rendicion" },
];

let lstEstados: Estado[] = [
  { id: "Todos", nombre: "Todos" },
  { id: "Aprobada", nombre: "Aprobada" },
  { id: "Anulada", nombre: "Anulada" },
  { id: "Autorizada", nombre: "Autorizada" },
  { id: "Cerrada", nombre: "Cerrada" },
  { id: "Creada", nombre: "Creada" },
  { id: "Despachado", nombre: "Despachado" },
  { id: "En Despacho", nombre: "En Despacho" },
  { id: "Enviada", nombre: "Enviada" },
  { id: "Guardada", nombre: "Guardada" },
  { id: "Rechazada", nombre: "Rechazada" },
];

let headers: any[] = [
  { title: "Id", key: "code", align: "center", width: "150px" },
  { title: "Tipo Solicitud", key: "tipoSolicitudId", align: "start" },
  { title: "Fecha Solicitud", key: "fechaSolicitud", align: "start" },
  { title: "Num. BC365", key: "bC365Id", width: "120px" },
  { title: "Empresa", key: "companyName", align: "center" },
  { title: "Departamento", key: "departamentoName", align: "center" },
  { title: "Ingresado Por", key: "solicitantePorNombre", align: "start" },
  { title: "Solicitante Por", key: "solicitanteNombre", align: "start" },
  { title: "Comprador", key: "compradorName" },
  { title: "Total", key: "total" },
  { title: "Estado", key: "estado", align: "start" },
  { title: "", key: "actions", align: "start" },
];

let esComprador: Ref<boolean> = ref(false);
let esBodeguero: Ref<boolean> = ref(false);
let esAprobador: Ref<boolean> = ref(false);
let esContabilidad: Ref<boolean> = ref(false);
let esAutorizador: Ref<boolean> = ref(false);
let esVisualizador: Ref<boolean> = ref(false);

let tipoSol: Ref<TipoSolicitud | null> = ref(lstTipoSolicitud[0]);
let estadoSel: Ref<Estado | null> = ref(lstEstados[0]);
let isLoading = ref(false);
let solId: Ref<string> = ref("");
// let date: Ref<string> = ref("");

let desde: Ref<string> = ref("");

let hasta: Ref<string> = ref("");
// hasta.value = new Date(
//   Date.now() - new Date().getTimezoneOffset() * 60000 + 90 * 24 * 60 * 60 * 1000
// )
//   .toISOString()
//   .substr(0, 10);

let solicitantePor: Ref<Empleado | null> = ref(null);
let empresaSel: Ref<Company | null> = ref(null);
let departamentoSel: Ref<Departamento | null> = ref(null);
let bodegaSel: Ref<Location | null> = ref(null);
let compradorSel: Ref<Empleado | null> = ref(null);
let solicitante: Ref<Empleado | null> = ref(null);
let bc365: Ref<string> = ref("");
let lstDetalle: Ref<BuscarSolicitud[]> = ref([]);
let rut: string | null = null;
let lstBodegas: Ref<Location[]> = ref([]);
let esAdmin: Ref<boolean> = ref(false);
let currentUser: Ref<Empleado | null> = ref(null);

onMounted(async () => {
  store.state.titulo = "Buscar Solicitud";

  await getCompanies();
  await getSolicitantes();
  await getEmpleadoComprador();
  // await getBodegasBuscador();

  if (localStorage.getItem("empresaSel") != null) {
    empresaSel.value = JSON.parse(localStorage.getItem("empresaSel") ?? "");

    if (empresaSel.value != null) {
      await getDepartamentos(empresaSel.value?.id);
    }
  } else {
    empresaSel.value = null;
  }

  localStorage.getItem("departamento") != null
    ? (departamentoSel.value = JSON.parse(
        localStorage.getItem("departamento") ?? ""
      ))
    : (departamentoSel.value = null);

  localStorage.getItem("solId") != null
    ? (solId.value = localStorage.solId)
    : (solId.value = "");

  // localStorage.getItem("ingresado") != null
  //   ? (solicitantePor.value = JSON.parse(
  //       localStorage.getItem("ingresado") ?? ""
  //     ))
  //   : (solicitantePor.value = null);

  localStorage.getItem("solicitante") != null
    ? (solicitante.value = JSON.parse(
        localStorage.getItem("solicitante") ?? ""
      ))
    : (solicitante.value = null);

  localStorage.getItem("tipoSol") != null
    ? (tipoSol.value = JSON.parse(localStorage.getItem("tipoSol") ?? ""))
    : (tipoSol.value = { id: "Todos", nombre: "Todos" });

  if (localStorage.getItem("bodega") != null) {
    if (empresaSel.value?.id !== undefined) {
      // await getBodegas(empresaSel.value?.id);
      await getBodegasBuscador();
    }
    bodegaSel.value = JSON.parse(localStorage.getItem("bodega") ?? "");
  } else {
    bodegaSel.value = null;
  }

  localStorage.getItem("bc365") != null
    ? (bc365.value = localStorage.bc365)
    : (bc365.value = "");

  // // // // localStorage.getItem("fecha") != null
  // // // //   ? (date.value = localStorage.fecha)
  // // // //   : (date.value = "");

  // console.log("desde: " + localStorage.getItem("desde"));
  if (localStorage.getItem("desde") != null) {
    desde.value = localStorage.desde;
  } else {
    if (localStorage.getItem("desde") == null) {
      desde.value = "";
    } else {
      desde.value = moment(
        new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)
      ).format("yyyy-MM-DD");
    }
  }

  // console.log("hasta: " + localStorage.getItem("hasta"));
  if (localStorage.getItem("hasta") != null) {
    hasta.value = localStorage.hasta;
  } else {
    if (localStorage.getItem("hasta") == null) {
      hasta.value = "";
    } else {
      hasta.value = moment(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      ).format("yyyy-MM-DD");
    }
  }

  localStorage.getItem("estadoSel") != null
    ? (estadoSel.value = JSON.parse(localStorage.getItem("estadoSel") ?? ""))
    : (estadoSel.value = { id: "Todos", nombre: "Todos" });

  localStorage.getItem("comprador") != null
    ? (compradorSel.value = JSON.parse(localStorage.getItem("comprador") ?? ""))
    : (compradorSel.value = null);

  if (cookies.get("username") != "") {
    await obtenerEmpleado(cookies.get("username"));
    // await obtenerEmpleado("pcerda@oxxeanchile.cl");
  }
});

async function getBodegasBuscador() {
  try {
    await axios
      .get(store.state.url + "api/Location/GetLocationsBuscador", {
        headers: {},
      })
      .then((response) => {
        if (response.status == 200) {
          lstBodegas.value = response.data as Location[];
          // console.log(lstBodegas.value);
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

async function obtenerEmpleado(email: string) {
  try {
    isLoading.value = true;

    await axios
      .get(store.state.url + "api/Empleado/GetEmpleadoXEmail?email=" + email, {
        headers: {},
      })
      .then(async (response) => {
        rut = response.data.rut;
        currentUser.value = response.data.rut;

        var roles = await getRol(email);
        if (response.status == 200) {
          if (
            roles != null &&
            roles.some((r) => r.rolNombre === "Administrador")
          ) {
            esAdmin.value = true;
          } else esAdmin.value = false;

          if (roles != null && roles.some((r) => r.rolNombre === "Aprobador")) {
            esAprobador.value = true;
          } else esAprobador.value = false;

          if (
            roles != null &&
            roles.some((r) => r.rolNombre === "Autorizador")
          ) {
            esAutorizador.value = true;
          } else esAutorizador.value = false;

          if (roles != null && roles.some((r) => r.rolNombre === "Comprador")) {
            esComprador.value = true;
          }

          if (
            roles != null &&
            roles.some((r) => r.rolNombre === "Contabilidad")
          ) {
            esContabilidad.value = true;
          }

          if (roles != null && roles.some((r) => r.rolNombre === "Bodega")) {
            esBodeguero.value = true;
          }

          if (
            roles != null &&
            roles.some((r) => r.rolNombre === "Visualizador")
          ) {
            esVisualizador.value = true;
          }

          if (
            !esAprobador.value &&
            !esAutorizador.value &&
            !esBodeguero.value &&
            !esContabilidad.value &&
            !esComprador.value &&
            !esVisualizador.value
            //&& !esAdmin.value
          ) {
            solicitantePor.value =
              lstSolicitantes.value.find((x) => x.email == email) || null;
          }
        }

        await buscarSolicitud();

        isLoading.value = false;
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

async function selectCompany() {
  try {
    isLoading.value = true;
    departamentoSel.value = null;
    lstDepartamento.value = [];

    if (empresaSel.value != null) {
      await getDepartamentos(empresaSel.value.id);
      // await getBodegasBuscador();
      // await getBodegas(empresaSel.value.id);
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function selectClear() {
  departamentoSel.value = null;
  lstDepartamento.value = [];
}

async function seleccionarRetiroBodega() {
  if (tipoSol.value?.id === "Retiro Bodega" /*&& empresaSel.value != null*/) {
    // await getBodegas(empresaSel.value?.id);
    await getBodegasBuscador();
  } else {
    lstBodegas.value = [];
    bodegaSel.value = null;
  }
}

function limpiarBusqueda() {
  // date.value = "";

  desde.value = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).format(
    "yyyy-MM-DD"
  );

  hasta.value = moment(
    new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  ).format("yyyy-MM-DD");

  // desde.value = "";
  // hasta.value = "";

  empresaSel.value = null;
  departamentoSel.value = null;
  lstDepartamento.value = [];
  lstBodegas.value = [];
  solId.value = "";
  solicitante.value = null;
  lstDetalle.value = [];
  bc365.value = "";
  bodegaSel.value = null;
  // solicitantePor.value = null;
  tipoSol.value = { id: "Todos", nombre: "Todos" };
  estadoSel.value = { id: "Todos", nombre: "Todos" };
  compradorSel.value = null;

  localStorage.removeItem("empresaSel");
  localStorage.removeItem("departamento");
  localStorage.removeItem("solId");
  localStorage.removeItem("ingresado");
  localStorage.removeItem("solicitante");
  localStorage.removeItem("tipoSol");
  localStorage.removeItem("bodega");
  localStorage.removeItem("bc365");
  localStorage.setItem("desde", desde.value);
  localStorage.setItem("hasta", hasta.value);

  localStorage.removeItem("estadoSel");
  localStorage.removeItem("comprador");
}

async function buscarSolicitud() {
  isLoading.value = true;

  try {
    var url =
      store.state.url +
      "api/SolicitudCompra/BuscarSolicitudCompra?estado=" +
      estadoSel.value?.id +
      "&rut=" +
      rut +
      "&email=" +
      // "pcerda@oxxeanchile.cl";
      cookies.get("username");

    localStorage.setItem("estadoSel", JSON.stringify(estadoSel.value));

    // if (date.value != "" && date.value != null) {
    //   url = url + "&fecha=" + date.value;
    //   localStorage.setItem("fecha", date.value);
    // }

    if (desde.value != null && hasta.value != null) {
      url = url + "&desde=" + desde.value + "&hasta=" + hasta.value;
      localStorage.setItem("desde", desde.value);
      localStorage.setItem("hasta", hasta.value);
    } else {
      localStorage.removeItem("desde");
      localStorage.removeItem("hasta");

      if (desde.value != null && hasta.value == null) {
        url = url + "&desde=" + desde.value;

        localStorage.setItem("desde", desde.value);
        localStorage.removeItem("hasta");
        // localStorage.setItem("hasta", "empty");
      }

      if (desde.value == null && hasta.value != null) {
        url = url + "&hasta=" + hasta.value;

        // localStorage.setItem("desde", "empty");
        localStorage.removeItem("desde");
        localStorage.setItem("hasta", hasta.value);
      }
    }

    if (empresaSel.value != null) {
      url = url + "&empresa=" + empresaSel.value.id;
      localStorage.setItem("empresaSel", JSON.stringify(empresaSel.value));
    }

    if (departamentoSel.value != null) {
      url = url + "&departamento=" + departamentoSel.value.id;
      localStorage.setItem(
        "departamento",
        JSON.stringify(departamentoSel.value)
      );
    }

    if (solId.value != "" && solId.value != null) {
      url = url + "&solId=" + solId.value;
      localStorage.setItem("solId", solId.value);
    }

    if (solicitantePor.value != null) {
      url = url + "&ingresado=" + solicitantePor.value.rut;
      // localStorage.setItem("ingresado", JSON.stringify(solicitantePor.value));
    }

    if (solicitante.value != null) {
      url = url + "&solicitante=" + solicitante.value.rut;
      localStorage.setItem("solicitante", JSON.stringify(solicitante.value));
    }

    if (tipoSol.value != null) {
      url = url + "&tipoSol=" + tipoSol.value.id;
      localStorage.setItem("tipoSol", JSON.stringify(tipoSol.value));
    }

    if (bodegaSel.value != null) {
      url = url + "&bodega=" + bodegaSel.value.code;
      localStorage.setItem("bodega", JSON.stringify(bodegaSel.value));
    }

    if (bc365.value != "" && bc365.value != null) {
      url = url + "&bc365=" + bc365.value;
      localStorage.setItem("bc365", bc365.value);
    }

    if (compradorSel.value != null) {
      url = url + "&comprador=" + compradorSel.value.rut;
      localStorage.setItem("comprador", JSON.stringify(compradorSel.value));
    }

    await axios
      .get(url, { headers: {} })
      .then(async (response) => {
        if (response.status == 200) {
          // lstDetalle.value = (await filtrarPorRol(response.data)) || [];
          lstDetalle.value = response.data as BuscarSolicitud[];

          var roles = await getRol(cookies.get("username"));
          // var roles = await getRol("pcerda@oxxeanchile.cl");

          if (roles != null && roles.some((r) => r.rolNombre === "Comprador")) {
            esComprador.value = true;
            esAprobador.value = false;
          }
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function getRol(email: string) {
  try {
    let tiene: Rol[] = [];

    await axios
      .get(store.state.url + "api/Rol/GetRol?email=" + email, {
        headers: {},
      })
      .then((response) => {
        if (response.status == 200) {
          tiene = response.data as Rol[];
        } else {
          console.log(response.statusText);
          tiene = [];
        }
      })
      .catch(function (error) {
        console.log(error.message);
        tiene = [];
      });

    return tiene;
  } catch (error) {
    console.log(error);
  }
}

function formatDate(value: string) {
  return moment(value).format("DD-MM-yyyy");
}

function formatTotal(value: number) {
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(value);
}

function navigate(item: BuscarSolicitud) {
  if (item.estado === "Guardada") {
    router.push({
      name: "EditarSolicitud",
      params: { solicitudId: item.id },
    });
  } else {
    router.push({
      name: "DetalleSolicitud",
      params: { solicitudId: item.id },
    });
  }
}
</script>
