<template>
  <div
    style="
      background-color: white;
      border-radius: 15px;
      margin: 20px;
      width: 500px;
      padding: 20px;
      margin-left: auto;
      margin-right: auto;
    "
  >
    <img
      src="../assets/rojo.svg"
      width="300"
      height="100"
      style="margin-left: auto; margin-right: auto; display: flex"
    />
    <h3 style="text-align: center; margin-top: 15px">Bienvenido</h3>

    <div v-if="!$store.state.isLogged" style="margin-top: 15px">
      <v-btn
        width="200"
        style="
          background-color: #595959;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        v-on:click="login()"
        >Ingresar</v-btn
      >
    </div>
    <div v-else style="margin-top: 15px">
      <v-btn
        width="200"
        style="
          background-color: #595959;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        v-on:click="logout()"
        >Cerrar Sesion</v-btn
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { PublicClientApplication } from "@azure/msal-browser";
import { onMounted } from "vue";
import AzureService from "../services/AzureService";
import { useStore } from "vuex";
import { key } from "@/store";
import Emmitter from "tiny-emitter";
import { useCookies } from "vue3-cookies";
import { useRouter, useRoute } from "vue-router";

const { cookies } = useCookies();
const store = useStore(key);
const router = useRouter();
const route = useRoute();

let $msalInstance: PublicClientApplication;
const $emitter = new Emmitter.TinyEmitter();

onMounted(async () => {
  const azureService = new AzureService();
  $msalInstance = new PublicClientApplication(
    azureService.getMsalConfig().value
  );

  await $msalInstance.initialize();
  await $msalInstance.handleRedirectPromise();
  $msalInstance.acquireTokenSilent({
    scopes: ["user.read", "mail.send"],
  });

  if (cookies.isKey("isLogged")) {
    store.state.isLogged = cookies.get("isLogged") === "true";
  }

  if (cookies.isKey("account")) {
    store.state.account = cookies.get("account");
    store.state.nombre = store.state.account.name; //this.account.name;
  }
});

async function login() {
  await $msalInstance
    .loginPopup()
    .then((response) => {
      const myAcccounts = $msalInstance.getAllAccounts();

      $emitter.emit("login", myAcccounts[0]);
      store.state.account = myAcccounts[0];
      cookies.set("isLogged", "true");
      cookies.set("token", response.accessToken);

      cookies.set("account", store.state.account);
      cookies.set(
        "username",
        store.state.account.username ?? store.state.account.preferred_username
      );

      store.state.isLogged = true;
      store.state.nombre = myAcccounts[0].name;

      localStorage.removeItem("empresaSel");
      localStorage.removeItem("departamento");
      localStorage.removeItem("solId");
      localStorage.removeItem("ingresado");
      localStorage.removeItem("solicitante");
      localStorage.removeItem("tipoSol");
      localStorage.removeItem("bodega");
      localStorage.removeItem("bc365");
      localStorage.removeItem("fecha");
      localStorage.removeItem("estadoSel");
      localStorage.removeItem("comprador");

      router.push({ name: "BuscaSolicitud" });
    })
    .catch((error: string) => {
      console.log(error);
    });
}

async function logout() {
  store.state.nombre = "Invitado";
  store.state.isLogged = false;
  store.state.email = "";

  cookies.remove("isLogged");
  cookies.remove("token");
  cookies.remove("account");

  localStorage.removeItem("empresaSel");
  localStorage.removeItem("departamento");
  localStorage.removeItem("solId");
  localStorage.removeItem("ingresado");
  localStorage.removeItem("solicitante");
  localStorage.removeItem("tipoSol");
  localStorage.removeItem("bodega");
  localStorage.removeItem("bc365");
  localStorage.removeItem("fecha");
  localStorage.removeItem("estadoSel");
  localStorage.removeItem("comprador");

  await $msalInstance.logout();
  $emitter.emit("logout");
}
</script>
