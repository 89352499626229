import { createRouter, createWebHistory } from "vue-router";
import type { RouteRecordRaw } from "vue-router";
import Login from "../views/Login.vue";
import SolCompra from "../views/SolCompra.vue";
import BuscaSolicitud from "../views/BuscaSolicitud.vue";
import DetalleSolicitud from "../views/DetalleSolicitud.vue";
import EditarSolicitud from "../views/EditarSolicitud.vue";
import RolMantenedor from "../views/RolMantenedor.vue";
import DeptoRolMantenedor from "../views/DeptoRolMantenedor.vue";
import EmpleadoMantenedor from "../views/EmpleadoMantenedor.vue";
//import AppVue from '../App.vue'

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/Login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/SolCompra/:solicitudId",
    name: "SolCompraParam",
    component: SolCompra,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/SolCompra",
    name: "SolCompra",
    component: SolCompra,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/BuscaSolicitud",
    name: "BuscaSolicitud",
    component: BuscaSolicitud,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/DetalleSolicitud/:solicitudId",
    name: "DetalleSolicitud",
    component: DetalleSolicitud,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/EditarSolicitud/:solicitudId",
    name: "EditarSolicitud",
    component: EditarSolicitud,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/RolMantenedor",
    name: "RolMantenedor",
    component: RolMantenedor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/DeptoRolMantenedor",
    name: "DeptoRolMantenedor",
    component: DeptoRolMantenedor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/EmpleadoMantenedor",
    name: "EmpleadoMantenedor",
    component: EmpleadoMantenedor,
    meta: {
      requiresAuth: true,
    },
  },
];

//set default route
//routes.push({ path: '/', redirect: '/Login' })
routes.push({ path: "/", redirect: "/BuscaSolicitud" });

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const needAuth = to.meta.requiresAuth;
  const isLogged = cookies.get("isLogged");

  //console.log('isLogged: ' + isLogged);

  if (needAuth && !isLogged) {
    next("Login");
  } else {
    next();
  }
});

export default router;
