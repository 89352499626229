import type { Ceco } from "@/types/ceco";
import type { Ref } from "vue";
import axios from "axios";
import { ref } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";

export default function useDepartamento() {
  const store = useStore(key);
  let lstCeco: Ref<Ceco[]> = ref([]);

  async function getCeco(empresa: string) {
    try {
      await axios
        .get(store.state.url + "api/Ceco/GetCecos?company=" + empresa, {
          headers: {},
        })
        .then((response) => {
          if (response.status == 200) {
            lstCeco.value = response.data as Ceco[];
            // console.log(lstCeco.value);
          } else {
            console.log(response.statusText);
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return { getCeco, lstCeco };
}
