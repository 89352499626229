import type { Location } from "@/types/location";
import axios from "axios";
import type { Ref } from "vue";
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";

export default function useSolicitante() {
  const store = useStore(key);
  let lstBodegas: Ref<Location[]> = ref([]);

  async function getBodegas(empresa: string) {
    try {
      await axios
        .get(store.state.url + "api/Location/GetLocations?company=" + empresa, {
          headers: {},
        })
        .then((response) => {
          if (response.status == 200) {
            lstBodegas.value = response.data as Location[];
            // console.log(lstBodegas.value);
          } else {
            console.log(response.statusText);
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return { getBodegas, lstBodegas };
}
