import type { IMsalConfig } from '@/interfaces/IMsalConfig';
import type {Ref} from 'vue';
import {ref} from 'vue';

class AzureService {
    private msalConfig: Ref<IMsalConfig>;
    private accessToken: Ref<string>;

    constructor() {
        this.msalConfig = ref({
            auth: {
                clientId: "a6e92956-ea45-43fd-9b9b-ad0dc6435c8f",
                authority: "https://login.microsoftonline.com/34dbdd58-057c-4f60-a2ab-f648c44a555b",
            },
            cache: {
                cacheLocation: "localStorage"
            }
        });

        this.accessToken = ref("");
    }

    setAccessToken(token : string): Ref<string> {
        this.accessToken.value = token;
        return this.accessToken;
    }

    getAccessToken(): Ref<string> {
        return this.accessToken;
    }

    getMsalConfig(): Ref<IMsalConfig> {
        return this.msalConfig;
    }
}

export default AzureService;