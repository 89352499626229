import type { Proyecto } from "@/types/proyecto";
import axios from "axios";
import type { Ref } from "vue";
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";

export default function useProyecto() {
  const store = useStore(key);
  let lstProyectos: Ref<Proyecto[]> = ref([]);

  async function getProyectos(empresa: string) {
    try {
      await axios
        .get(store.state.url + "api/Proyecto/GetProyectos?company=" + empresa, {
          headers: {},
        })
        .then((response) => {
          if (response.status == 200) {
            lstProyectos.value = response.data as Proyecto[];
          } else {
            console.log(response.statusText);
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return { getProyectos, lstProyectos };
}
