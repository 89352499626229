import type { Empleado } from "@/types/empleado";
import axios from "axios";
import type { Ref } from "vue";
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";

export default function useSolicitante() {
    const store = useStore(key);
    let lstSolicitantes: Ref<Empleado[]> = ref([]);

    async function getSolicitantes() {
        try {
          await axios
            .get(store.state.url + "api/Empleado/GetEmpleados", { headers: {} })
            .then((response) => {
              if (response.status == 200) {
                lstSolicitantes.value = response.data as Empleado[];
              } else {
                console.log(response.statusText);
              }
            })
            .catch(function (error) {
              console.log(error.message);
            });
        } catch (error) {
          console.log(error);
        }
      }

    return { getSolicitantes, lstSolicitantes };
}