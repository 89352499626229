<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2>Agregar Roles</h2>

    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label>Rol:</label>

        <v-combobox
          v-model="rolSel"
          :items="lstRoles"
          variant="solo"
          autocomplete="off"
          hide-details
          pa-1
          clearable
          ma-0
          item-title="nombre"
          item-value="id"
        >
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Empleado:</label>

        <v-combobox
          v-model="empleadoSel"
          :items="lstSolicitantes"
          variant="solo"
          hide-details
          autocomplete="off"
          pa-1
          clearable
          ma-0
          item-title="rutDisplayName"
          item-value="rut"
        >
        </v-combobox>
      </div>

      <v-btn
        @click="addRol"
        style="
          background-color: #a3a3a3;
          padding: 15px;
          border-radius: 15px;
          margin-top: 2.4em;
          color: white;
          display: flex;
        "
        dark
        :disabled="!empleadoSel || !rolSel"
      >
        Guardar
      </v-btn>
    </div>

    <br />
    <h2>Roles</h2>

    <v-text-field
      v-model="search"
      label="Buscar por empleado, email o rol"
      class="ma-2 w-50"
      autocomplete="off"
      density="compact"
      prepend-inner-icon="mdi-magnify"
      single-line
      variant="solo"
      hide-details
    ></v-text-field>

    <v-data-table :headers="headers" :items="lstTablaRoles" :search="search">
      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-update" color="red" @click="showUpdateDialog(item)" />
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>
  </div>

  <v-dialog v-model="dialogDelete" persistent width="auto">
    <v-card>
      <v-card-title class="text-h5"> Borrar Rol </v-card-title>
      <v-card-text> Esta seguro que desea borrar este rol? </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialogDelete = false">No</v-btn>
        <v-btn color="primary" @click="deleteRolTable(rolToDelete)">Si</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogUpdate" persistent width="1024">
    <v-card>
      <v-card-title>
        <span class="text-h5">Actualizar Rol</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                :items="lstRoles"
                variant="solo"
                v-model="rolSelUpd"
                hide-details
                pa-1
                ma-0
                autocomplete="off"
                item-title="nombre"
                item-value="id"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-combobox
                :items="lstSolicitantes"
                v-model="emplSelUpd"
                variant="solo"
                hide-details
                pa-1
                autocomplete="off"
                ma-0
                item-title="rutDisplayName"
                item-value="rut"
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="dialogUpdate = false"
        >
          Cerrar
        </v-btn>
        <v-btn
          :disabled="!emplSelUpd || !rolSelUpd"
          color="blue-darken-1"
          variant="text"
          @click="updateRol(rolToUpdate)"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { reactive, ref } from "vue";
import { onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import { key } from "@/store";
import swal from "sweetalert2";

import type { Rol } from "../types/rol";
import type { TipoRol } from "../types/tipoRol";

import useSolicitante from "../composables/useSolicitante";
import type { Empleado } from "../types/empleado";

const store = useStore(key);
const { getSolicitantes, lstSolicitantes } = useSolicitante();

let lstRoles: TipoRol[] = [
  { id: "Administrador", nombre: "Administrador" },
  { id: "Aprobador", nombre: "Aprobador" },
  { id: "Autorizador", nombre: "Autorizador" },
  { id: "Bodega", nombre: "Bodega" },
  { id: "Comprador", nombre: "Comprador" },
  { id: "Contabilidad", nombre: "Contabilidad" },
  { id: "Visualizador", nombre: "Visualizador" },
];

let rolSel: Ref<TipoRol | null> = ref(null);
let empleadoSel: Ref<Empleado | null> = ref(null);

let lstTablaRoles: Ref<Rol[]> = ref([]);
let headers: any = [
  { title: "Empleado", key: "nombre", align: "start" },
  { title: "Email", key: "email", align: "start" },
  { title: "Rol", key: "rolNombre", align: "start" },
  { title: "", key: "actions", align: "start", width: "100px" },
];

let dialogDelete: Ref<boolean> = ref(false);
let dialogUpdate: Ref<boolean> = ref(false);
let rolToDelete: Ref<Rol> = ref({});
let rolToUpdate: Ref<Rol> = ref({});
let emplSelUpd: Ref<Empleado | null> = ref(null);
let rolSelUpd: Ref<TipoRol | null> = ref(null);
let search: Ref<string> = ref("");

onMounted(async () => {
  store.state.titulo = "Mantenedor Roles";

  await getSolicitantes();
  await getRoles();
});

async function getRoles() {
  try {
    await axios
      .get(store.state.url + "api/Rol/GetRoles", { headers: {} })
      .then((response) => {
        if (response.status == 200) {
          lstTablaRoles.value = response.data as Rol[];
          //console.log(this.lstTablaRoles);
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

async function addRol() {
  try {
    let rol: Rol = {
      nombre: empleadoSel.value?.fullName,
      email: empleadoSel.value?.email,
      rolNombre: rolSel.value?.id,
    };

    await axios
      .post(store.state.url + "api/Rol/AddRol", rol, {
        headers: {},
      })
      .then((response) => {
        if (response.status == 200) {
          lstTablaRoles.value.unshift(rol);
          console.log("Rol agregado");

          swal.fire({
            icon: "success",
            title: "Rol Creado Exitosamente",
            showConfirmButton: false,
            timer: 2000,
          });

          empleadoSel.value = null;
          rolSel.value = null;
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

async function deleteRol(item: Rol) {
  try {
    await axios
      .delete(store.state.url + "api/Rol/DeleteRol?id=" + item.id, {
        headers: {},
      })
      .then((response) => {
        if (response.status == 200) {
          //this.getRoles();
          console.log("Rol eliminado");
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

async function updateRol(item: Rol) {
  try {
    var rol: Rol = {
      id: item.id,
      nombre: emplSelUpd.value?.fullName,
      email: emplSelUpd.value?.email,
      rolNombre: rolSelUpd.value?.id,
    };

    await axios
      .put(store.state.url + "api/Rol/UpdateRol", rol, {
        headers: {},
      })
      .then(async (response) => {
        if (response.status == 200) {
          console.log("Rol actualizado");

          await getRoles();

          swal.fire({
            icon: "success",
            title: "Rol Actualizado Exitosamente",
            showConfirmButton: false,
            timer: 2000,
          });

          dialogUpdate.value = !dialogUpdate.value;
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

function deleteRolTable(item: Rol) {
  deleteRol(item).then(() => {
    const index = lstTablaRoles.value.indexOf(item);
    lstTablaRoles.value.splice(index, 1);

    dialogDelete.value = !dialogDelete.value;
  });
}

function showDeleteDialog(item: Rol) {
  rolToDelete.value = item;
  dialogDelete.value = !dialogDelete.value;
}

function showUpdateDialog(item: Rol) {
  rolToUpdate.value = item;

  emplSelUpd.value =
    lstSolicitantes.value.find((x) => x.email == item.email) || null;
  rolSelUpd.value = lstRoles.find((x) => x.id == item.rolNombre) || null;

  dialogUpdate.value = !dialogUpdate.value;
}
</script>
