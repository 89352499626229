<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2 style="margin-bottom: 15px; font-weight: 600; border-bottom: 1px solid">
      {{ "SOC-" + code.toString().padStart(7, "0") }} - Datos Generales
    </h2>

    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label><b></b>Tipo Solicitud:</label>

        <v-autocomplete
          pa-1
          ma-0
          hide-details
          v-model="tipoSol"
          autocomplete="off"
          :items="lstTipoSolicitud"
          variant="solo"
          item-title="id"
          item-value="nombre"
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in">
        <label>Fecha Solicitud:</label>

        <v-text-field
          pa-1
          ma-0
          hide-details
          autocomplete="off"
          v-model="date"
          type="date"
          variant="solo"
          color="primary"
          disabled
          required
        />
      </div>

      <div class="w-in">
        <label>Ingresado Por:</label>

        <v-text-field
          variant="solo"
          pa-1
          ma-0
          autocomplete="off"
          v-model="solPorId"
          readonly
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label> Rut Ingresado Por: </label>
        <v-text-field
          variant="solo"
          pa-1
          autocomplete="off"
          ma-0
          v-model="solPorNombre"
          readonly
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Empresa:</label>

        <v-autocomplete
          v-model="empresaSel"
          pa-1
          hide-details
          ma-0
          :items="lstEmpresas"
          autocomplete="off"
          variant="solo"
          item-title="displayName"
          item-value="id"
          @update:modelValue="selectCompany"
          clearable
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in">
        <label>Departamento:</label>

        <v-autocomplete
          v-model="departamentoSel"
          pa-1
          ma-0
          autocomplete="off"
          hide-details
          :items="lstDepartamento"
          variant="solo"
          item-title="codeDisplayName"
          item-value="id"
          clearable
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in">
        <label>Solicitante:</label>

        <v-autocomplete
          v-model="solicitanteSel"
          :items="lstSolicitantes"
          variant="solo"
          pa-1
          hide-details
          autocomplete="off"
          ma-0
          item-title="rutDisplayName"
          item-value="personId"
          clearable
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in" v-if="tipoSol.id == 'Retiro Bodega'">
        <label>Cod. Bod:</label>

        <v-autocomplete
          variant="solo"
          :items="lstBodegas"
          hide-details
          pa-1
          ma-0
          autocomplete="off"
          clearable
          item-title="codeDisplayName"
          item-value="id"
          v-model="codigoBodega"
        >
        </v-autocomplete>
      </div>

      <div class="w-in" v-if="tipoSol.id == 'Compra'">
        <label>Proveedor:</label>

        <v-autocomplete
          v-model="vendorSel"
          :items="lstVendors"
          variant="solo"
          item-title="numberDisplayName"
          item-value="id"
          autocomplete="off"
          clearable
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in" v-if="tipoSol.id == 'Compra'">
        <label>Comprador:</label>

        <v-autocomplete
          v-model="compradorSel"
          :items="lstCompradores"
          variant="solo"
          item-title="rutDisplayName"
          item-value="rut"
          clearable
          hide-details
          autocomplete="off"
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in">
        <label>Observación:</label>

        <v-textarea
          variant="solo"
          pa-1
          ma-0
          v-model="obs"
          hide-details
          autocomplete="off"
        >
        </v-textarea>
      </div>

      <div v-if="adjuntos.length > 0" class="w-100">
        <label>{{ adjuntos.length }} Archivos Adjuntos:</label>

        <div class="text-center d-flex flex-wrap">
          <div
            v-for="(adj, index) in adjuntos"
            :key="index"
            style="align-items: center"
          >
            <v-tooltip :text="adj.nombre">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  class="ma-2"
                  style="
                    height: 55px;
                    background-color: #a61a2f;
                    color: white;
                    border-radius: 11px;
                  "
                  @click="bajarAdjunto(sliceString(adj.url))"
                >
                  <v-icon size="x-large">{{ setIcon(adj.nombre) }}</v-icon>
                  <div>Archivo {{ index + 1 }}</div>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>

    <h2 style="font-weight: 600; margin-top: 10px">Detalle Solicitud</h2>

    <div class="w-in">
      <v-combobox
        style="font-size: 16px; margin-bottom: 10px"
        pa-1
        ref="mySearch"
        :loading="loading"
        :items="lstItems"
        autocomplete="off"
        :hide-no-data="false"
        v-model="item"
        item-title="numberDisplayName"
        item-value="id"
        variant="solo"
        dense
        hide-details
        clearable
        label="Busca un Material/Servicio"
        no-data-text="No se encontro Material/Servicio"
        @update:search="BuscarMaterial"
        return-object
      >
      </v-combobox>
    </div>

    <v-data-table
      v-if="tipoSol.id == 'Compra'"
      :headers="headers"
      :items="lstDetalle"
    >
      <template v-slot:item.cantidad="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          autocomplete="off"
          ma-0
          v-model="item.cantidad"
          @keypress="filterNumber(event)"
          @update:modelValue="calcularTotalCantidad(item)"
        >
        </v-text-field>
      </template>

      <template v-slot:item.precio="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          autocomplete="off"
          v-model="item.precio"
          @update:modelValue="calcularTotalCantidad(item)"
        >
        </v-text-field>
      </template>

      <template v-slot:item.total="{ item }">
        {{ formatTotal(item.total) }}
      </template>

      <template v-slot:item.cecoId="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstCeco"
          density="compact"
          pa-1
          ma-0
          hide-details
          autocomplete="off"
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          v-model="item.cecoId"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.proyectoId="{ item }">
        <v-autocomplete
          v-model="item.proyectoId"
          pa-1
          ma-0
          hide-details
          :items="lstProyectos"
          variant="outlined"
          density="compact"
          autocomplete="off"
          item-title="codeDisplayName"
          item-value="id"
          clearable
          :return-object="false"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.codigoBodega="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstBodegas"
          density="compact"
          hide-details
          autocomplete="off"
          pa-1
          ma-0
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          v-model="item.codigoBodega"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.oTMantencion="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          autocomplete="off"
          ma-0
          v-model="item.oTMantencion"
        >
        </v-text-field>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>

    <v-data-table
      v-if="tipoSol != null && tipoSol.id == 'Retiro Bodega'"
      :headers="headersRetiro"
      :items="lstDetalle"
    >
      <template v-slot:item.cantidad="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          autocomplete="off"
          ma-0
          @keypress="filterNumber(event)"
          v-model="item.cantidad"
        >
        </v-text-field>
      </template>

      <template v-slot:item.cecoId="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstCeco"
          density="compact"
          pa-1
          ma-0
          hide-details
          item-title="codeDisplayName"
          item-value="id"
          autocomplete="off"
          :return-object="false"
          v-model="item.cecoId"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>

    <v-data-table
      v-if="tipoSol.id === 'Rendicion'"
      :headers="headersRendicion"
      :items="lstDetalle"
    >
      <template v-slot:item.tipoDoc="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstTipoDoc"
          density="compact"
          pa-1
          ma-0
          hide-details
          item-title="nombre"
          autocomplete="off"
          item-value="id"
          :return-object="false"
          v-model="item.tipoDoc"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.nroDoc="{ item }">
        <v-text-field
          pa-1
          ma-0
          v-model="item.nroDoc"
          variant="outlined"
          color="primary"
          autocomplete="off"
          required
          density="compact"
          hide-details
        />
      </template>

      <template v-slot:item.fechaDoc="{ item }">
        <v-text-field
          pa-1
          ma-0
          v-model="item.fechaDoc"
          type="date"
          variant="outlined"
          color="primary"
          autocomplete="off"
          required
          density="compact"
          hide-details
        />
      </template>

      <template v-slot:item.Material="{ item }">
        {{ item.Material }}
      </template>

      <template v-slot:item.proveedorId="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstVendors"
          density="compact"
          autocomplete="off"
          pa-1
          ma-0
          hide-details
          item-title="numberDisplayName"
          item-value="id"
          :return-object="false"
          v-model="item.proveedorId"
          clearable
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.descGasto="{ item }">
        <v-text-field
          pa-1
          ma-0
          v-model="item.descGasto"
          variant="outlined"
          autocomplete="off"
          color="primary"
          required
          density="compact"
          hide-details
        />
      </template>

      <template v-slot:item.monto="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          autocomplete="off"
          ma-0
          v-model="item.monto"
          @keypress="filterOnlyNumber(event)"
          @update:modelValue="rendicionTotal(item)"
        >
        </v-text-field>
      </template>

      <template v-slot:item.cecoId="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstCeco"
          density="compact"
          pa-1
          ma-0
          hide-details
          item-title="codeDisplayName"
          autocomplete="off"
          item-value="id"
          :return-object="false"
          v-model="item.cecoId"
          clearable
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.proyectoId="{ item }">
        <v-autocomplete
          v-model="item.proyectoId"
          pa-1
          ma-0
          :items="lstProyectos"
          variant="outlined"
          item-title="codeDisplayName"
          item-value="id"
          autocomplete="off"
          density="compact"
          clearable
          :return-object="false"
          hide-details
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>

    <div
      v-if="tipoSol.id != 'Retiro Bodega'"
      style="
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
        display: flex;
        justify-content: flex-end;
      "
    >
      Total: {{ formatMoney(total) }}
      <!-- Total: {{ total }} -->
    </div>

    <div class="d-flex flex-wrap">
      <div class="w-in">
        <v-file-input
          pa-1
          ma-0
          v-model="selectedFiles"
          multiple
          autocomplete="off"
          label="Selecciona archivos"
          variant="solo"
          @update:modelValue="addFiles"
        >
        </v-file-input>

        <v-chip closable v-for="f in file" v-bind:key="f.name">
          {{ f.name }}
        </v-chip>
      </div>
      <div class="w-69 d-flex mt-3" style="justify-content: end">
        <v-btn
          style="
            background-color: #595959;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-right: 5px;
            display: flex;
          "
          density="default"
          v-on:click="guardarSolicitud()"
          >Guardar</v-btn
        >

        <v-btn
          style="
            background-color: #10510e;
            padding: 15px;
            border-radius: 15px;
            color: white;
            display: flex;
          "
          density="default"
          v-on:click="crearSolicitud()"
          >Crear Solicitud</v-btn
        >
      </div>
    </div>
  </div>

  <v-dialog v-model="dialogDelete" persistent width="auto">
    <v-card>
      <v-card-title class="text-h5"> Borrar Item </v-card-title>
      <v-card-text>
        Esta seguro que desea item el material `{{
          itemToDelete.codigoMaterial
        }}` ?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialogDelete = false">No</v-btn>
        <v-btn color="primary" @click="deleteMaterial(itemToDelete)">Si</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-overlay
    :model-value="isLoading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular
      color="#a61a2f"
      size="50"
      :width="5"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { reactive, ref } from "vue";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import axios from "axios";
import { useCookies } from "vue3-cookies";
import { useRouter, useRoute } from "vue-router";
import swal from "sweetalert2";
import { PublicClientApplication } from "@azure/msal-browser";
import AzureService from "../services/AzureService";
import currency from "currency.js";

import type { SolicitudCompra } from "../types/solicitudCompra";
import type { Company } from "../types/company";
import type { Departamento } from "../types/departamento";
import type { Vendors } from "../types/vendors";
import type { Empleado } from "../types/empleado";
import type { Adjunto } from "../types/adjunto";
import type { Location } from "../types/location";
import type { SolicitudDetalle } from "../types/solicitudDetalle";
import type { Rol } from "../types/rol";
import type { Proyecto } from "../types/proyecto";
import type { TipoSolicitud } from "../types/tipoSolicitud";
import type { Item } from "../types/item";
import type { SolicitudEncabezado } from "../types/SolicitudEncabezado";
import type { TipoDocumento } from "../types/tipoDocumento";

import useDepartamentos from "../composables/useDepartamento";
import useCeco from "../composables/useCeco";
import useVendors from "../composables/useVendors";
import useComprador from "../composables/useComprador";
import useBodegas from "../composables/useBodega";
import useProyecto from "../composables/useProyecto";
import useSolicitante from "../composables/useSolicitante";

const { cookies } = useCookies();
const store = useStore(key);
const router = useRouter();
const route = useRoute();

const { getDepartamentos, lstDepartamento } = useDepartamentos();
const { getCeco, lstCeco } = useCeco();
const { getVendors, lstVendors } = useVendors();
const { getEmpleadoComprador, lstCompradores } = useComprador();
const { getBodegas, lstBodegas } = useBodegas();
const { getProyectos, lstProyectos } = useProyecto();
const { getSolicitantes, lstSolicitantes } = useSolicitante();

let $msalInstance: PublicClientApplication;
let isLoading: Ref<boolean> = ref(true);

let lstTipoSolicitud: TipoSolicitud[] = [
  { id: "Compra", nombre: "Compra" },
  { id: "Retiro Bodega", nombre: "Retiro Bodega" },
  { id: "Rendicion", nombre: "Rendicion" },
];

let lstTipoDoc: TipoDocumento[] = [
  { id: "Sin Documento", nombre: "Sin Documento" },
  { id: "Boleta", nombre: "Boleta" },
  { id: "Factura", nombre: "Factura" },
  { id: "Vale", nombre: "Vale" },
  { id: "B. Honorarios", nombre: "B. Honorarios" },
];

let tipoSol: Ref<TipoSolicitud> = ref(lstTipoSolicitud[0]);
let lstEmpresas: Ref<Company[]> = ref([]);
let solPorId: Ref<string> = ref("");
let solPorNombre: Ref<string> = ref("");

let empresaSel: Ref<Company | null> = ref(null);
let departamentoSel: Ref<Departamento | null> = ref(null);
let vendorSel: Ref<Vendors | null> = ref(null);
let compradorSel: Ref<Empleado | null> = ref(null);
let proyectoSel: Ref<Proyecto | null> = ref(null);
let solicitanteSel: Ref<Empleado | null> = ref(null);

let codigoBodega: Ref<Location | null> = ref(null);
let idSolicitud: string = "";
let date: Ref<string> = ref("");
let code: Ref<number> = ref(0);
let obs: Ref<string> = ref("");
let estado: Ref<string> = ref("");
let tipo: Ref<string> = ref("");
let solId: Ref<string> = ref("");
let solNombre: Ref<string> = ref("");
let dialogCerrar: Ref<boolean> = ref(false);
const lstDetalle: SolicitudDetalle[] = reactive([]);
let adjuntos: Ref<Adjunto[]> = ref([]);
let total: Ref<number> = ref(0);
let detContador: number = 1;
let lstItems: Ref<Item[]> = ref([]);
let item: Ref<Item | string | null> = ref(null);
let loading: boolean = false;
let selectedFiles: Ref<File[]> = ref([]);
let file: Ref<File[]> = ref([]);
let dialogDelete: Ref<boolean> = ref(false);
let itemToDelete: SolicitudDetalle = {};
let codBodega: string = "";
let usuario: Ref<Empleado | null> = ref(null);
let autorizador: Ref<string> = ref("");

let headers: any = [
  { title: "Id", key: "id", width: "25px" },
  { title: "Cod", key: "codigoMaterial", align: "center", width: "120px" },
  {
    title: "Material/Servicio",
    key: "material",
    align: "center",
    width: "300px",
  },
  { title: "UN", key: "unidad", align: "center" },
  { title: "Cantidad", key: "cantidad", align: "center", width: "100px" },
  { title: "Precio", key: "precio", align: "center", width: "150px" },
  { title: "Total", key: "total", align: "center", width: "150px" },
  { title: "Ceco", key: "cecoId", align: "start", width: "250px" },
  { title: "Proyecto", key: "proyectoId", align: "center", width: "200px" },
  { title: "Bodega", key: "codigoBodega", align: "center", width: "200px" },
  {
    title: "OT Mantención",
    key: "oTMantencion",
    align: "center",
    width: "50px",
  },
  { title: "", key: "actions", align: "center", width: "25px" },
];

let headersRetiro: any = [
  { title: "Id", key: "id", width: "25px" },
  { title: "Tipo", key: "tipo", align: "start", width: "100px" },
  {
    title: "Cod",
    key: "codigoMaterial",
    align: "center",
    width: "120px",
  },
  {
    title: "Material/Servicio",
    key: "material",
    align: "start",
    width: "150px",
  },
  { title: "UN", key: "unidad", align: "center", width: "50px" },
  { title: "Cantidad", key: "cantidad", align: "center", width: "100px" },
  { title: "Ceco", key: "cecoId", align: "start", width: "250px" },
  { title: "", key: "actions", align: "center", width: "25px" },
];

let headersRendicion: any = [
  { title: "Id", key: "id", width: "25px" },
  {
    title: "Tipo Docto",
    key: "tipoDoc",
    align: "center",
    width: "220px",
  },
  { title: "N° Docto", key: "nroDoc", align: "center", width: "100px" },
  {
    title: "Fecha Docto",
    key: "fechaDoc",
    align: "center",
    width: "200px",
  },
  {
    title: "Proveedor",
    key: "proveedorId",
    align: "center",
    width: "200px",
  },
  {
    title: "Material/Servicio",
    key: "material",
    align: "center",
    width: "150px",
  },
  {
    title: "Descripción del Gasto",
    key: "descGasto",
    align: "center",
    width: "150px",
  },
  { title: "Ceco", key: "cecoId", align: "center", width: "250px" },
  {
    title: "Proyecto",
    key: "proyectoId",
    align: "center",
    width: "250px",
  },
  { title: "Monto", key: "monto", align: "center", width: "150px" },
  { title: "", key: "actions", align: "center", width: "25px" },
];

onMounted(async () => {
  const azureService = new AzureService();

  $msalInstance = new PublicClientApplication(
    azureService.getMsalConfig().value
  );

  store.state.titulo = "Editar Solicitud";

  await getCompanies();

  // isLoading.value = false;
});

async function obtenerEmpleado(email: string) {
  try {
    isLoading.value = true;

    await axios
      .get(store.state.url + "api/Empleado/GetEmpleadoXEmail?email=" + email, {
        headers: {},
      })
      .then(async (response) => {
        if (response.status == 200) {
          usuario.value = response.data;
          autorizador.value = response.data.rut;
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function BuscarMaterial() {
  if (empresaSel.value == null) {
    swal.fire({
      icon: "warning",
      title: "Debe seleccionar una empresa",
      showConfirmButton: true,
    });

    item.value = null;
    return;
  }

  let det: SolicitudDetalle = {};

  if (typeof item.value === "string" || item.value instanceof String) {
    loading = true;

    if (item.value != null && new String(item.value).length > 2) {
      axios
        .get(
          store.state.url +
            "api/Item/BuscadorItem?item=" +
            item.value +
            "&companyId=" +
            empresaSel.value.id,
          {
            headers: {},
          }
        )
        .then((response) => {
          if (response.status == 200) {
            lstItems.value = response.data;
            loading = false;
          } else {
            console.log(response.statusText);
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
      // .finally(() => (loading = false));
    }
  } else {
    if (item.value != null) {
      if (typeof item.value == "string" || item.value instanceof String) {
        return;
      }

      if (tipoSol.value.id == "Rendicion") {
        det = {
          idItem: item.value.id,
          id: detContador,
          encabezadoId: idSolicitud,
          codigoMaterial: item.value.number,
          material: item.value.displayName,
          retiroBodega: false,
          monto: null,
          oTMantencion: null,
          tipo: item.value.itemCategoryCode,
          generalProductPostingGroupCode:
            item.value.generalProductPostingGroupCode,
          cantidad: item.value.type == "Service" ? 1 : null,
        };
      } else {
        if (tipoSol.value.id == "Retiro Bodega") {
          det = {
            idItem: item.value.id,
            id: detContador,
            tipo: item.value.itemCategoryCode,
            encabezadoId: idSolicitud,
            codigoMaterial: item.value.number,
            material: item.value.displayName,
            unidad: item.value.baseUnitOfMeasureCode,
            cantidad: item.value.type == "Service" ? 1 : null,
            precio: null,
            total: 0,
            retiroBodega: true,
            oTMantencion: null,
          };
        }

        if (tipoSol.value.id == "Compra") {
          det = {
            idItem: item.value.id,
            id: detContador,
            tipo: item.value.itemCategoryCode,
            encabezadoId: idSolicitud,
            codigoMaterial: item.value.number,
            material: item.value.displayName,
            unidad: item.value.baseUnitOfMeasureCode,
            cantidad: item.value.type == "Service" ? 1 : null,
            precio: null,
            total: 0,
            oTMantencion: null,
          };
        }
      }

      if (det != null && lstDetalle != null) {
        lstDetalle.push(det);

        item.value = null;
        detContador++;
      }

      lstItems.value = [];
    }
  }
}

function getCompanies() {
  try {
    axios
      .get(store.state.url + "api/Company/GetCompanies", { headers: {} })
      .then(async (response) => {
        if (response.status == 200) {
          lstEmpresas.value = response.data as Company[];

          getSolicitudCompra();
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

function getSolicitudCompra() {
  try {
    axios
      .get(
        store.state.url +
          "api/SolicitudCompra/GetSolicitudCompra?solicitudId=" +
          route.params.solicitudId
      )
      .then(async (response) => {
        if (response.status == 200) {
          var solicitud = response.data;

          let validarUsuario = await verificarUsuario(
            solicitud.encabezado.tipoSolicitudId,
            solicitud.encabezado.departamentoId,
            solicitud.encabezado.solicitantePorId,
            solicitud.encabezado.solicitanteId
          );

          if (!validarUsuario) {
            swal.fire({
              title: "Error",
              text: "No tiene permisos para ver esta solicitud",
              icon: "error",
              confirmButtonText: "Aceptar",
            });

            router.push({ name: "BuscaSolicitud" });
            return;
          }

          idSolicitud = solicitud.encabezado.id;
          tipoSol.value.id = solicitud.encabezado.tipoSolicitudId;
          date.value = solicitud.encabezado.fechaSolicitud.substr(0, 10);
          solPorId.value = solicitud.encabezado.solicitantePorId;
          solPorNombre.value = solicitud.encabezado.solicitantePorNombre;
          solId.value = solicitud.encabezado.solicitanteId;
          solNombre.value = solicitud.encabezado.solicitanteNombre;
          code.value = solicitud.encabezado.code;

          if (solicitud.encabezado.companyId != null) {
            empresaSel.value = lstEmpresas.value.find(
              (r) => r.id == solicitud.encabezado.companyId
            ) as Company;
          }

          if (empresaSel.value != null) {
            localStorage.setItem("empresa", JSON.stringify(empresaSel.value));

            await getDepartamentos(empresaSel.value.id);
            await getCeco(empresaSel.value.id);
            await getBodegas(empresaSel.value.id);
            await getVendors(empresaSel.value.id);
            await getProyectos(empresaSel.value.id);
            await getEmpleadoComprador();
          }

          await getSolicitantes();

          if (cookies.get("username") != "") {
            obtenerEmpleado(cookies.get("username"));
            // obtenerEmpleado("truiz@oxxeanchile.cl");
          }

          if (solicitud.encabezado.departamentoId != null) {
            departamentoSel.value = lstDepartamento.value.find(
              (d) => d.id == solicitud.encabezado.departamentoId
            ) as Departamento;
          }

          solicitanteSel.value = lstSolicitantes.value.find(
            (s) => s.rut == solicitud.encabezado.solicitanteId
          ) as Empleado;

          obs.value = solicitud.encabezado.observacion;
          adjuntos.value = (await getFiles(idSolicitud)) || [];

          vendorSel.value = lstVendors.value.find(
            (v) => v.id == solicitud.encabezado.vendorId
          ) as Vendors;

          estado.value = solicitud.encabezado.estado;
          tipo.value = solicitud.encabezado.tipoSolicitudId;

          if (solicitud.encabezado.proyectoId != null) {
            proyectoSel.value = lstProyectos.value.find(
              (p) => p.id == solicitud.encabezado.proyectoId
            ) as Proyecto;
          }

          if (tipo.value == "Retiro Bodega") {
            // codigoDes = solicitud.encabezado.codigoDespacho;
            codigoBodega.value = lstBodegas.value.find(
              (b) => b.id == solicitud.encabezado.codigoBodega
            ) as Location;
          }

          if (tipo.value == "Compra") {
            // idBc365 = solicitud.encabezado.bC365Id;
            codigoBodega.value = lstBodegas.value.find(
              (b) => b.id == solicitud.encabezado.codigoBodega
            ) as Location;

            compradorSel.value = lstCompradores.value.find(
              (c) => c.rut == solicitud.encabezado.comprador
            ) as Empleado;

            for (let i = 0; i < solicitud.detalle.length; i++) {
              solicitud.detalle[i].total =
                solicitud.detalle[i].cantidad * solicitud.detalle[i].precio;
            }
          }

          if (tipoSol.value.id == "Rendicion") {
            for (let i = 0; i < solicitud.detalle.length; i++) {
              if (solicitud.detalle[i].fechaDoc != null) {
                solicitud.detalle[i].fechaDoc = solicitud.detalle[
                  i
                ].fechaDoc.substr(0, 10);
              }

              if (empresaSel == null) {
                solicitud.detalle[i].cecoId = null;
                solicitud.detalle[i].proyectoId = null;
              }
            }
          }

          for (let i = 0; i < solicitud.detalle.length; i++) {
            lstDetalle.push(solicitud.detalle[i]);
          }

          if (tipoSol.value.id == "Compra") {
            solicitud.detalle.forEach((e: SolicitudDetalle) => {
              if (e.total != null) {
                total.value += e.total;
              }
            });
          }

          detContador = lstDetalle.length + 1;
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => (isLoading.value = false));
  } catch (error) {
    console.log(error);
  }
}

async function getFiles(idSolicitud: string) {
  try {
    var archivos: Adjunto[] = [];

    await axios
      .get(
        store.state.url +
          "api/SolicitudCompra/GetSolicitudFiles?solicitudId=" +
          idSolicitud
      )
      .then((response) => {
        if (response.status == 200) {
          archivos = response.data;
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });

    return archivos;
  } catch (error) {
    console.log(error);
  }
}

function bajarAdjunto(filename: string) {
  try {
    isLoading.value = true;

    const link = document.createElement("a");
    link.href =
      store.state.url + "api/SolicitudCompra/DownloadFile?fileName=" + filename;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function guardarSolicitud() {
  try {
    var encab: SolicitudEncabezado = {};
    var detalle: SolicitudDetalle[] = [];

    if (
      solPorId.value == null ||
      solPorId.value == "" ||
      solPorNombre.value == null ||
      solPorNombre.value == ""
    ) {
      swal.fire({
        icon: "warning",
        title: "Debe haber un ingresado por",
        showConfirmButton: true,
      });
      return;
    }

    isLoading.value = true;

    if (tipoSol.value.id == "Compra" || tipoSol.value.id == "Retiro Bodega") {
      encab = {
        id: idSolicitud,
        fechaSolicitud: date.value,
        solicitantePorId: solPorId.value,
        solicitantePorNombre: solPorNombre.value,
        companyId: empresaSel.value != null ? empresaSel.value?.id : null,
        departamentoId:
          departamentoSel.value != null ? departamentoSel.value?.id : null,
        observacion: obs.value,
        solicitanteId:
          solicitanteSel.value != null ? solicitanteSel.value?.rut : null,
        solicitanteNombre:
          solicitanteSel.value != null ? solicitanteSel.value?.fullName : null,
        vendorId: vendorSel.value != null ? vendorSel.value?.id : null,
        estado: "Guardada",
        tipoSolicitudId: tipoSol.value.id,
        proyectoId: proyectoSel.value != null ? proyectoSel.value?.id : null,
        comprador: compradorSel.value != null ? compradorSel.value?.rut : null,
      };

      // if (typeof codBodega === "string" || codBodega instanceof String) {
      encab.codigoBodega = codBodega != null ? codBodega : null;
      // } else {
      // encab.codigoBodega = codBodega != null ? codBodega.id : null;
      // }

      detalle = lstDetalle;

      // for (let i = 0; i < detalle.length; i++) {
      //   var validCantidad = currency(detalle[i].cantidad, {
      //     decimal: ",",
      //     errorOnInvalid: true,
      //   });

      //   if (isNaN(validCantidad.value)) {
      //     swal.fire({
      //       icon: "warning",
      //       title: "La cantidad no es válida",
      //       showConfirmButton: true,
      //     });
      //     return;
      //   }

      //   detalle[i].cantidad = validCantidad.value;
      // }

      let solicitud: SolicitudCompra = {
        encabezado: encab,
        detalle: detalle,
      };

      // console.log(JSON.stringify(solicitud));
      enviarSolicitudGuardada(solicitud);
      //}
    } else {
      if (tipoSol.value.id == "Rendicion") {
        encab = {
          id: idSolicitud,
          fechaSolicitud: date.value,
          solicitantePorId: solPorId.value,
          solicitantePorNombre: solPorNombre.value,
          companyId: empresaSel.value != null ? empresaSel.value.id : null,
          departamentoId:
            departamentoSel.value != null ? departamentoSel.value.id : null,
          observacion: obs.value,
          solicitanteId:
            solicitanteSel.value != null ? solicitanteSel.value.rut : null,
          solicitanteNombre:
            solicitanteSel.value != null ? solicitanteSel.value.fullName : null,
          estado: "Guardada",
          tipoSolicitudId: tipoSol.value.id,
          proyectoId: proyectoSel.value != null ? proyectoSel.value.id : null,
        };

        detalle = lstDetalle;

        for (let i = 0; i < detalle.length; i++) {
          if (departamentoSel != null) {
            detalle[i].deptoId = departamentoSel.value?.id;
          }
        }

        let solicitud: SolicitudCompra = {
          encabezado: encab,
          detalle: detalle,
        };

        //console.log(JSON.stringify(solicitud));
        enviarSolicitudGuardada(solicitud);
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function enviarSolicitudGuardada(solicitud: SolicitudCompra) {
  isLoading.value = true;

  axios
    .post(
      store.state.url + "api/SolicitudCompra/PostSolicitudCompraGuardada",
      solicitud
    )
    .then(async (response) => {
      if (response.status == 200) {
        if (file.value.length > 0) {
          await submitFile(solicitud);
        }

        date.value = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);

        swal.fire({
          icon: "success",
          title: "Solicitud Guardada Exitosamente",
          showConfirmButton: false,
          timer: 2000,
        });

        router.push({
          name: "BuscaSolicitud",
        });
      } else {
        swal.fire({
          icon: "error",
          title: "Error al guardar la solicitud: " + response.statusText,
          showConfirmButton: true,
        });
      }
    })
    .catch((error) => {
      // errorMessage = error.message;
      swal.fire({
        icon: "error",
        title: "Error al guardar la solicitud: " + error.message,
        showConfirmButton: true,
      });
    })
    .finally(() => (isLoading.value = false));
}

async function crearSolicitud() {
  try {
    if (tipoSol.value == null) {
      swal.fire({
        icon: "warning",
        title: "Debe seleccionar un tipo de solicitud",
        showConfirmButton: true,
      });
      return;
    }

    if (empresaSel.value == null) {
      swal.fire({
        icon: "warning",
        title: "Debe seleccionar una empresa",
        showConfirmButton: true,
      });
      return;
    }

    if (departamentoSel.value == null) {
      swal.fire({
        icon: "warning",
        title: "Debe seleccionar un departamento",
        showConfirmButton: true,
      });
      return;
    }

    if (solicitanteSel.value == null) {
      swal.fire({
        icon: "warning",
        title: "Debe seleccionar un Solicitante",
        showConfirmButton: true,
      });
      return;
    }

    if (lstDetalle.length == 0) {
      swal.fire({
        icon: "warning",
        title: "Debe agregar al menos un material",
        showConfirmButton: true,
      });
      return;
    }

    if (lstDetalle.some((x) => x.cecoId == null)) {
      swal.fire({
        icon: "warning",
        title: "Debe seleccionar un ceco",
        showConfirmButton: true,
      });
      return;
    }

    if (
      solPorId.value == null ||
      solPorId.value == "" ||
      solPorNombre.value == null ||
      solPorNombre.value == ""
    ) {
      swal.fire({
        icon: "warning",
        title: "Debe haber un ingresado por",
        showConfirmButton: true,
      });
      return;
    }

    var encab: SolicitudEncabezado = {};
    var detalle: SolicitudDetalle[] = [];

    isLoading.value = true;

    if (tipoSol.value.id == "Compra" || tipoSol.value.id == "Retiro Bodega") {
      if (lstDetalle.some((x) => x.cantidad == 0)) {
        swal.fire({
          icon: "warning",
          title: "Debe ingresar una cantidad mayor a 0",
          showConfirmButton: true,
        });
        return;
      }

      if (tipoSol.value.id == "Retiro Bodega") {
        if (codigoBodega == null) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar una bodega",
            showConfirmButton: true,
          });
          return;
        }
      }

      encab = {
        id: idSolicitud,
        fechaSolicitud: date.value,
        solicitantePorId: solPorId.value,
        solicitantePorNombre: solPorNombre.value,
        companyId: empresaSel.value?.id,
        departamentoId: departamentoSel.value?.id,
        observacion: obs.value,
        solicitanteId: solicitanteSel.value?.rut,
        solicitanteNombre: solicitanteSel.value?.fullName,
        estado: "Creada",
        tipoSolicitudId: tipoSol.value.id,
        proyectoId: proyectoSel != null ? proyectoSel.value?.id : null,
      };

      detalle = lstDetalle;

      if (tipoSol.value.id == "Compra") {
        if (vendorSel == null) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar un proveedor",
            showConfirmButton: true,
          });
          return;
        }

        if (compradorSel == null) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar un comprador",
            showConfirmButton: true,
          });
          return;
        }

        encab.vendorId = vendorSel != null ? vendorSel.value?.id : null;
        encab.comprador = compradorSel.value?.rut;

        for (let i = 0; i < detalle.length; i++) {
          if (
            detalle[i].codigoMaterial?.includes("MAT-") &&
            detalle[i].codigoBodega == null
          ) {
            swal.fire({
              icon: "warning",
              title:
                "Se debe seleccionar bodega para el item " +
                detalle[i].material,
              showConfirmButton: true,
            });
            return;
          }

          if (
            route.params.solicitudId != undefined ||
            route.params.solicitudId != null
          ) {
            detalle[i].cecoId = detalle[i].cecoId;
            detalle[i].proyectoId = detalle[i].proyectoId;
            detalle[i].codigoBodega = detalle[i].codigoBodega;
          }
        }
      }

      if (tipoSol.value.id == "Retiro Bodega") {
        if (codigoBodega == null) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar una bodega",
            showConfirmButton: true,
          });
          return;
        }

        encab.codigoBodega =
          codigoBodega != null ? codigoBodega.value?.id : null;

        if (
          route.params.solicitudId != undefined ||
          route.params.solicitudId != null
        ) {
          encab.codigoBodega =
            codigoBodega != null ? codigoBodega.value?.id : null;

          for (let i = 0; i < detalle.length; i++) {
            detalle[i].cecoId = detalle[i].cecoId;
            detalle[i].proyectoId = detalle[i].proyectoId;
          }
        }
      }

      for (let i = 0; i < detalle.length; i++) {
        var validCantidad = currency(detalle[i].cantidad, {
          decimal: ",",
          errorOnInvalid: true,
        });

        if (isNaN(validCantidad.value)) {
          swal.fire({
            icon: "warning",
            title: "La cantidad no es válida",
            showConfirmButton: true,
          });
          return;
        }

        detalle[i].cantidad = validCantidad.value;
      }

      let solicitud: SolicitudCompra = {
        encabezado: encab,
        detalle: detalle,
      };

      // console.log(JSON.stringify(solicitud));
      enviarSolicitud(solicitud);
    } else {
      if (tipoSol.value.id == "Rendicion") {
        if (lstDetalle.some((x) => x.tipoDoc == null)) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar un tipo de documento",
            showConfirmButton: true,
          });
          return;
        }

        if (lstDetalle.some((x) => x.fechaDoc == null)) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar una fecha de documento",
            showConfirmButton: true,
          });
          return;
        }

        if (
          lstDetalle.some(
            (x) =>
              x.nroDoc == null &&
              x.tipoDoc != "Sin Documento" &&
              x.tipoDoc != "Vale"
          )
        ) {
          loading = false;

          swal.fire({
            icon: "warning",
            title: "Debe ingresar un numero de documento",
            showConfirmButton: true,
          });
          return;
        }

        if (
          lstDetalle.some(
            (x) => x.proveedorId == null && x.tipoDoc == "Factura"
          )
        ) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar un proveedor",
            showConfirmButton: true,
          });
          return;
        }

        if (
          lstDetalle.some(
            (x) => x.proveedorId == null && x.tipoDoc == "B. Honorarios"
          )
        ) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar un proveedor",
            showConfirmButton: true,
          });
          return;
        }

        if (lstDetalle.some((x) => x.descGasto == null)) {
          swal.fire({
            icon: "warning",
            title: "Debe ingresar una descripción del gasto",
            showConfirmButton: true,
          });
          return;
        }

        if (lstDetalle.some((x) => x.monto == null)) {
          swal.fire({
            icon: "warning",
            title: "Debe ingresar un monto",
            showConfirmButton: true,
          });
          return;
        }

        encab = {
          id: idSolicitud,
          fechaSolicitud: date.value,
          solicitantePorId: solPorId.value,
          solicitantePorNombre: solPorNombre.value,
          companyId: empresaSel.value?.id,
          departamentoId: departamentoSel.value?.id,
          observacion: obs.value,
          solicitanteId: solicitanteSel.value?.rut,
          solicitanteNombre: solicitanteSel.value?.fullName,
          estado: "Creada",
          tipoSolicitudId: tipoSol.value?.id,
        };

        detalle = lstDetalle;

        for (let i = 0; i < detalle.length; i++) {
          detalle[i].deptoId = departamentoSel.value?.id;
        }

        let solicitud: SolicitudCompra = {
          encabezado: encab,
          detalle: detalle,
        };

        //console.log(JSON.stringify(solicitud));
        enviarSolicitud(solicitud);
        //}
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function enviarSolicitud(solicitud: SolicitudCompra) {
  axios
    .post(
      store.state.url + "api/SolicitudCompra/PostSolicitudCompra",
      solicitud
    )
    .then(async (response) => {
      if (response.status == 200) {
        if (file.value.length > 0) {
          await submitFile(solicitud);
        }

        date.value = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);

        empresaSel.value = null;
        departamentoSel.value = null;
        vendorSel.value = null;
        solicitanteSel.value = null;
        obs.value = "";

        lstItems.value = [];
        //lstDetalle = [];
        file.value = [];

        detContador = 1;

        swal.fire({
          icon: "success",
          title: "Solicitud Creada Exitosamente",
          showConfirmButton: false,
          timer: 2000,
        });

        if (cookies.get("username") != "") {
          obtenerEmpleado(cookies.get("username"));
          // obtenerEmpleado("truiz@oxxeanchile.cl");
        }

        await sendEmail(solicitud.encabezado.id);

        router.push({
          name: "BuscaSolicitud",
        });
      } else {
        swal.fire({
          icon: "error",
          title: "Error al guardar la solicitud: " + response.statusText,
          showConfirmButton: true,
        });
      }
    })
    .catch((error) => {
      // errorMessage = error.message;
      swal.fire({
        icon: "error",
        title: "Error al guardar la solicitud: " + error.message,
        showConfirmButton: true,
      });
    })
    .finally(() => (isLoading.value = false));
}

async function submitFile(solicitud: SolicitudCompra) {
  const files = new FormData();

  for (let i = 0; i < file.value.length; i++) {
    files.append("files", file.value[i]);
  }
  //files.append("files", this.file);
  const headers = { "Content-Type": "multipart/form-data" };

  await axios
    .post(store.state.url + "api/SolicitudCompra/UploadMultipleFile", files, {
      headers,
    })
    .then(async (res) => {
      if (res.status == 200) {
        await postFiles(res.data, solicitud.encabezado.id);
        // console.log(tipo);
      } else {
        console.log("Error al cargar archivo, status code:" + res.status);
      }
    })
    .finally(() => (isLoading.value = false));
}

async function postFiles(files: File, solId: string | undefined) {
  await axios
    .post(
      store.state.url + "api/SolicitudCompra/PostFiles?solicitudId=" + solId,
      files,
      { headers: {} }
    )
    .then((response) => {
      if (response.status == 200) {
        console.log("Archivos subidos correctamente");
      } else {
        console.log(response.statusText);
      }
    })
    .catch(function (error) {
      console.log(error.message);
    });
}

async function sendEmail(idSol: string | undefined) {
  try {
    await refreshToken()
      .then(async () => {
        var token = cookies.get("token");

        await axios
          .post(
            store.state.url + "api/SolicitudCompra/SendEmail?idSol=" + idSol,
            token,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            if (response.status == 200) {
              console.log(response.data);
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((error: any) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
}

async function refreshToken() {
  try {
    await $msalInstance.initialize();

    var redirectResponse = await $msalInstance.handleRedirectPromise();
    if (redirectResponse !== null) {
      cookies.set("token", redirectResponse.accessToken);
    } else {
      var myAcccount = $msalInstance.getAllAccounts();

      await $msalInstance
        .acquireTokenSilent({
          scopes: ["User.Read", "Mail.Send"],
          account: myAcccount[0],
        })
        .then((response: any) => {
          cookies.set("token", response.accessToken);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  } catch (error) {
    console.log(error);
  }
}

async function verificarUsuario(
  tipo: string,
  depto: string,
  solPorId: string,
  solId: string
) {
  let verificado = false;

  await obtenerEmpleado(cookies.get("username"));
  // await this.obtenerEmpleado("czambrano@oxxeanchile.cl");

  var roles = await getRol(cookies.get("username"));
  // var roles = await this.getRol("czambrano@oxxeanchile.cl");

  if (roles != null && roles.some((r) => r.rolNombre === "Administrador")) {
    return true;
  }

  if (usuario != null && usuario.value.rut == solId) {
    return true;
  }

  if (usuario != null && usuario.value.rut == solPorId) {
    return true;
  }

  if (
    tipo == "Compra" &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Comprador")
  ) {
    verificado = true;
  }

  if (
    tipo == "Retiro Bodega" &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Bodega")
  ) {
    verificado = true;
  }

  if (
    tipo == "Rendicion" &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Contabilidad")
  ) {
    verificado = true;
  }

  if (
    (tipo == "Compra" || tipo == "Retiro Bodega") &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Visualizador")
  ) {
    let deptosViusalizador = await getDeptosRol(
      cookies.get("username"),
      // "czambrano@oxxeanchile.cl",
      "Visualizador"
    );

    if (deptosViusalizador?.some((d) => d == depto)) {
      verificado = true;
    }
  }

  if (
    tipo == "Rendicion" &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Autorizador")
  ) {
    let deptosAutorizador = await getDeptosRol(
      cookies.get("username"),
      // "czambrano@oxxeanchile.cl",
      "Autorizador"
    );

    if (deptosAutorizador?.some((d) => d == depto)) {
      verificado = true;
    }
  }

  if (
    (tipo == "Compra" || tipo == "Retiro Bodega") &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Aprobador")
  ) {
    let deptosAprobador = await getDeptosRol(
      (roles != null && cookies.get("username")) || "",
      // "czambrano@oxxeanchile.cl",
      "Aprobador"
    );

    if (deptosAprobador?.some((d) => d == depto)) {
      verificado = true;
    }
  }

  return verificado;
}

async function getDeptosRol(email: string, rol: string) {
  try {
    var deptos: string[] = [];

    await axios
      .get(
        store.state.url + "api/Rol/GeDeptostRol?email=" + email + "&rol=" + rol,
        {
          headers: {},
        }
      )
      .then((response) => {
        if (response.status == 200) {
          deptos = response.data;
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });

    return deptos;
  } catch (error) {
    console.log(error);
  }
}

async function getRol(email: string) {
  try {
    var tiene: Rol[] = [];

    await axios
      .get(store.state.url + "api/Rol/GetRol?email=" + email, {
        headers: {},
      })
      .then((response) => {
        if (response.status == 200) {
          tiene = response.data as Rol[];
        } else {
          console.log(response.statusText);
          tiene = [];
        }
      })
      .catch(function (error) {
        console.log(error.message);
        tiene = [];
      });

    return tiene;
  } catch (error) {
    console.log(error);
  }
}

async function addFiles() {
  for (var f of selectedFiles.value) {
    file.value.push(f);
  }

  selectedFiles.value = [];
}

function rendicionTotal() {
  total.value = lstDetalle.reduce((acc, item) => acc + item.monto, 0);
}

async function selectCompany() {
  try {
    if (localStorage.getItem("empresa") != null) {
      await swal
        .fire({
          icon: "warning",
          title: "Se perderán los datos ingresados, ¿Desea continuar?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          allowOutsideClick: false,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            isLoading.value = true;
            if (
              typeof empresaSel.value != "string" &&
              empresaSel.value != null
            ) {
              localStorage.setItem("empresa", JSON.stringify(empresaSel.value));

              departamentoSel.value = null;
              vendorSel.value = null;
              solicitanteSel.value = null;
              lstDetalle = [];

              await getDepartamentos(empresaSel.value.id);
              await getVendors(empresaSel.value.id);
              await getProyectos(empresaSel.value.id);
              await getCeco(empresaSel.value.id);
              await getBodegas(empresaSel.value.id);
            }
          } else {
            empresaSel.value = JSON.parse(
              localStorage.getItem("empresa") || ""
            );
          }
        });
    } else {
      localStorage.setItem("empresa", JSON.stringify(empresaSel.value));

      await getDepartamentos(empresaSel.value?.id || "");
      await getVendors(empresaSel.value?.id || "");
      await getProyectos(empresaSel.value?.id || "");
      await getCeco(empresaSel.value?.id || "");
      await getBodegas(empresaSel.value?.id || "");
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

function setIcon(fileName: string) {
  try {
    var icon = "";

    var ext = fileName.substring(fileName.lastIndexOf(".") + 1);

    switch (ext) {
      case "pdf":
        icon = "mdi-file-pdf-box";
        break;

      case "doc":
        icon = "mdi-file-word";
        break;

      case "docx":
        icon = "mdi-file-word";
        break;

      case "xls":
        icon = "mdi-file-excel";
        break;

      case "xlsx":
        icon = "mdi-file-excel";
        break;

      case "ppt":
        icon = "mdi-file-powerpoint";
        break;

      case "pptx":
        icon = "mdi-file-powerpoint";
        break;

      case "jpeg":
        icon = "mdi-file-image";
        break;

      case "png":
        icon = "mdi-file-image";
        break;

      case "gif":
        icon = "mdi-file-image";
        break;

      case "jpg":
        icon = "mdi-file-image";
        break;

      case "7z":
        icon = "mdi-zip-box";
        break;

      case "rar":
        icon = "mdi-zip-box";
        break;

      case "zip":
        icon = "mdi-zip-box";
        break;

      case "txt":
        icon = "mdi-file-document";
        break;

      default:
        icon = "mdi-file";
        break;
    }

    return icon;
  } catch (error) {
    console.log(error);
  }
}

function sliceString(value: string) {
  return value.substring(value.lastIndexOf("/") + 1);
}

function formatMoney(value: number) {
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
    minimumFractionDigits: 2,
  }).format(value);
}

function filterNumber(evt: any) {
  evt = evt ? evt : window.event;
  let expect = evt.target.value.toString() + evt.key.toString();

  if (!/^[0-9]*\.?[0-9]*$/.test(expect)) {
    evt.preventDefault();
  } else {
    return true;
  }
}

function formatTotal(value: number | null): string {
  if (value == null) {
    return "";
  }
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(value);
}

function filterOnlyNumber(evt: any) {
  evt = evt ? evt : window.event;
  let expect = evt.target.value.toString() + evt.key.toString();

  //solo numeros
  if (!/^[0-9]*$/.test(expect)) {
    evt.preventDefault();
  } else {
    return true;
  }
}

function calcularTotalCantidad(item: SolicitudDetalle) {
  item.precio = item.precio?.toString().replace(/,/g, "").replace(/\./g, "");
  item.cantidad = item.cantidad.toString().replace(/,/g, ".");

  item.total = parseFloat(item.cantidad) * parseFloat(item.precio);
  compraTotal();
}

function formatoCantidad(item: SolicitudDetalle) {
  //si item.cantidad contiene una coma, reemplazarla por un punto
  item.cantidad = item.cantidad.toString().replace(/,/g, ".");
}

function compraTotal() {
  total.value = lstDetalle.reduce((acc, item) => acc + item.total, 0);
}

function showDeleteDialog(item: SolicitudDetalle) {
  itemToDelete = item;
  dialogDelete.value = !dialogDelete.value;
}

function deleteMaterial(item: SolicitudDetalle) {
  //const index = lstDetalle.values.indexOf(item);
  let index = lstDetalle.findIndex((x) => x.id == item.id);
  lstDetalle.splice(index, 1);

  dialogDelete.value = !dialogDelete;

  for (let i = 0; i < lstDetalle.length; i++) {
    lstDetalle[i].id = i + 1;
  }

  detContador = lstDetalle.length + 1;
}
</script>
