import type { Departamento } from "@/types/departamento";
import type { Ref } from "vue";
import axios from "axios";
import { ref } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";

export default function useDepartamento() {
  const store = useStore(key);
  let lstDepartamento: Ref<Departamento[]> = ref([]);

  async function getDepartamentos(empresa: string) {
    try {
      await axios
        .get(
          store.state.url +
            "api/Departamento/GetDepartamentos?company=" +
            empresa,
          {
            headers: {},
          }
        )
        .then((response) => {
          if (response.status == 200) {
            lstDepartamento.value = response.data as Departamento[];
          } else {
            console.log(response.statusText);
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return { getDepartamentos, lstDepartamento };
}
