import type { Company } from "@/types/company";
import axios from "axios";
import type { Ref } from "vue";
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";


export default function useEmpresas() {
    const store = useStore(key);
    let lstEmpresas: Ref<Company[]> = ref([]);

    async function getCompanies() {
        try {
            await axios
              .get(store.state.url + "api/Company/GetCompanies", { headers: {} })
              .then((response) => {
                if (response.status == 200) {
                    lstEmpresas.value = response.data as Company[];
                } else {
                  console.log(response.statusText);
                }
              })
              .catch(function (error) {
                console.log(error.message);
              });
          } catch (error) {
            console.log(error);
          }
    }
  
    return { getCompanies, lstEmpresas}; 
}