<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2>Agregar Departamento Rol</h2>

    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label>Rol:</label>

        <v-combobox
          v-model="rolSel"
          :items="lstRoles"
          variant="solo"
          hide-details
          pa-1
          autocomplete="off"
          clearable
          ma-0
          item-title="nombre"
          item-value="id"
        >
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Empleado:</label>

        <v-combobox
          v-model="empleadoSel"
          :items="lstEmpleados"
          variant="solo"
          hide-details
          pa-1
          autocomplete="off"
          clearable
          ma-0
          item-title="rutDisplayName"
          item-value="rut"
        >
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Departamento:</label>

        <v-combobox
          v-model="departamentoSel"
          :items="lstDepartamento"
          variant="solo"
          pa-1
          hide-details
          ma-0
          autocomplete="off"
          item-title="codeDisplayName"
          item-value="id"
          clearable
        >
        </v-combobox>
      </div>

      <v-btn
        style="
          background-color: #a3a3a3;
          padding: 15px;
          border-radius: 15px;
          margin-top: 2.4em;
          color: white;
          display: flex;
        "
        dark
        @click="addDepartamentoEmpleados"
        :disabled="!empleadoSel || !rolSel || !departamentoSel"
      >
        Guardar
      </v-btn>
    </div>

    <br />
    <h2>Departamento Roles</h2>

    <v-text-field
      v-model="search"
      label="Buscar por departamento, empleado o rol"
      class="ma-2 w-50"
      density="compact"
      prepend-inner-icon="mdi-magnify"
      autocomplete="off"
      single-line
      variant="solo"
      hide-details
    ></v-text-field>

    <v-data-table :headers="headers" :items="lstTablaRoles" :search="search">
      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-update" color="red" @click="showUpdateDialog(item)" />
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>
  </div>

  <v-dialog v-model="dialogDelete" persistent width="auto">
    <v-card>
      <v-card-title class="text-h5"> Borrar Departamento Rol </v-card-title>
      <v-card-text>
        Esta seguro que desea borrar este Departamento rol?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialogDelete = false">No</v-btn>
        <v-btn color="primary" @click="deleteRolTable(rolToDelete)">Si</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogUpdate" persistent width="1024">
    <v-card>
      <v-card-title>
        <span class="text-h5">Actualizar Departamento Rol</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                :items="lstRoles"
                v-model="rolSelUpd"
                variant="solo"
                hide-details
                pa-1
                clearable
                ma-0
                autocomplete="off"
                item-title="nombre"
                item-value="id"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                :items="lstEmpleados"
                v-model="emplSelUpd"
                variant="solo"
                hide-details
                autocomplete="off"
                pa-1
                clearable
                ma-0
                item-title="rutDisplayName"
                item-value="rut"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                :items="lstDepartamento"
                variant="solo"
                v-model="deptoSelUpd"
                pa-1
                hide-details
                autocomplete="off"
                ma-0
                item-title="codeDisplayName"
                item-value="id"
                clearable
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="dialogUpdate = false"
        >
          Cerrar
        </v-btn>
        <v-btn
          :disabled="!emplSelUpd || !rolSelUpd || !deptoSelUpd"
          color="blue-darken-1"
          variant="text"
          @click="updateRol(rolToUpdate)"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-overlay
    :model-value="isLoading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular
      color="#a61a2f"
      size="50"
      :width="5"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { reactive, ref } from "vue";
import { onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import { key } from "@/store";
import swal from "sweetalert2";
import type { Empleado } from "../types/empleado";
import type { Departamento } from "../types/departamento";
import type { TipoRol } from "../types/tipoRol";
import type { DepartamentoEmpleado } from "../types/departamentoEmpleado";

const store = useStore(key);

let lstEmpleados: Ref<Empleado[]> = ref([]);
let empleadoSel: Ref<Empleado | null> = ref(null);

let lstDepartamento: Ref<Departamento[]> = ref([]);
let departamentoSel: Ref<Departamento | null> = ref(null);

let lstRoles: TipoRol[] = [
  { id: "Aprobador", nombre: "Aprobador" },
  { id: "Autorizador", nombre: "Autorizador" },
  { id: "Visualizador", nombre: "Visualizador" },
];

let rolSel: Ref<TipoRol | null> = ref(null);
let lstTablaRoles: Ref<DepartamentoEmpleado[]> = ref([]);

let headers: any = [
  { title: "Departamento", key: "departamentoId", align: "start" },
  { title: "Empleado", key: "empleadoName", align: "start" },
  { title: "Rol", key: "rolEmail", align: "start" },
  { title: "", key: "actions", align: "start", width: "100px" },
];

let dialogDelete: Ref<boolean> = ref(false);
let dialogUpdate: Ref<boolean> = ref(false);

let rolToDelete: Ref<DepartamentoEmpleado | null> = ref(null);
let rolToUpdate: Ref<DepartamentoEmpleado | null> = ref(null);

let emplSelUpd: Ref<Empleado | null> = ref(null);
let rolSelUpd: Ref<TipoRol | null> = ref(null);
let deptoSelUpd: Ref<Departamento | null> = ref(null);
let search: Ref<string> = ref("");
let isLoading: Ref<boolean> = ref(false);

onMounted(async () => {
  try {
    store.state.titulo = "Mantenedor Departamento Roles";

    await getSolicitantes();
    await getDepartamentos();
    await getDepartamentosEmpleados();
  } catch (error) {
    console.log(error);
  }
});

async function getSolicitantes() {
  try {
    await axios
      .get(store.state.url + "api/Empleado/GetEmpleadosMantenedor", {
        headers: {},
      })
      .then((response) => {
        if (response.status == 200) {
          lstEmpleados.value = response.data as Empleado[];
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

async function getDepartamentos() {
  try {
    await axios
      .get(store.state.url + "api/Departamento/GetDepartamentosMantenedor", {
        headers: {},
      })
      .then((response) => {
        if (response.status == 200) {
          lstDepartamento.value = response.data as Departamento[];
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

async function getDepartamentosEmpleados() {
  try {
    isLoading.value = true;

    await axios
      .get(
        store.state.url + "api/DepartamentoEmpleado/GetDepartamentosEmpleados",
        { headers: {} }
      )
      .then((response) => {
        if (response.status == 200) {
          lstTablaRoles.value = response.data;
          //console.log(this.lstTablaRoles);
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function addDepartamentoEmpleados() {
  try {
    var depto: DepartamentoEmpleado = {
      departamentoId: departamentoSel.value?.code,
      empleadoId: empleadoSel.value?.email,
      rolEmail: rolSel.value?.id,
    };

    await axios
      .post(
        store.state.url + "api/DepartamentoEmpleado/AddDepartamentoEmpleados",
        depto,
        {
          headers: {},
        }
      )
      .then((response) => {
        if (response.status == 200) {
          console.log("Rol Depto creado");

          swal.fire({
            icon: "success",
            title: "Rol Departamento Creado Exitosamente",
            showConfirmButton: false,
            timer: 2000,
          });

          rolSel.value = null;
          empleadoSel.value = null;
          departamentoSel.value = null;

          getDepartamentosEmpleados();
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

async function deleteRol(item: DepartamentoEmpleado) {
  try {
    await axios
      .delete(
        store.state.url +
          "api/DepartamentoEmpleado/DeleteDepartamentoEmpleados?id=" +
          item.id,
        {
          headers: {},
        }
      )
      .then((response) => {
        if (response.status == 200) {
          //this.getRoles();
          console.log("Rol Depto eliminado");
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

function deleteRolTable(item: DepartamentoEmpleado) {
  deleteRol(item).then(() => {
    const index = lstTablaRoles.value.indexOf(item);
    lstTablaRoles.value.splice(index, 1);

    dialogDelete.value = !dialogDelete.value;
  });
}

async function updateRol(item: DepartamentoEmpleado) {
  try {
    var depto: DepartamentoEmpleado = {
      id: item.id,
      departamentoId: deptoSelUpd.value?.code,
      empleadoId: emplSelUpd.value?.email,
      rolEmail: rolSelUpd.value?.id,
    };

    await axios
      .put(
        store.state.url +
          "api/DepartamentoEmpleado/UpdateDepartamentoEmpleados",
        depto,
        {
          headers: {},
        }
      )
      .then((response) => {
        if (response.status == 200) {
          console.log("Rol Depto actualizado");

          swal.fire({
            icon: "success",
            title: "Rol Departamento Actualizado Exitosamente",
            showConfirmButton: false,
            timer: 2000,
          });

          getDepartamentosEmpleados();

          dialogUpdate.value = !dialogUpdate.value;
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

function showDeleteDialog(item: DepartamentoEmpleado) {
  rolToDelete.value = item;
  dialogDelete.value = !dialogDelete.value;
}

function showUpdateDialog(item: DepartamentoEmpleado) {
  rolToUpdate.value = item;

  emplSelUpd.value =
    lstEmpleados.value.find((x) => x.email == item.empleadoId) || null;

  rolSelUpd.value = lstRoles.find((x) => x.id == item.rolEmail) || null;

  deptoSelUpd.value =
    lstDepartamento.value.find((x) => x.code == item.departamentoId) || null;

  dialogUpdate.value = !dialogUpdate.value;
}
</script>
